import styled from 'styled-components'

interface AbilityContentConfig {
  alingItems: string
}
export const StyledAboutUsContent = styled.div`
  padding: 5%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`
export const StyledImgAbilityContent = styled.div<AbilityContentConfig>`
  height: 170px;
  display: flex;
  align-items: ${(props) => props.alingItems};

  @media all and (max-width: 480px) {
    img {
      margin: -10px 0;
    }
  }
`
export const StyledImgAbilityBoxContent = styled.div`
  width: 100%;
  display: flex;
  margin: 5% 0;
  justify-content: space-between;

  @media all and (max-width: 480px) {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
`
