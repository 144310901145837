import styled from 'styled-components'
export const StyledTitleH1 = styled.h1`
  font-size: 60px;
  margin: 15px 0;
  font-weight: 700;
  line-height: 65px;

  @media all and (min-width: 1024px) and (max-width: 1280px) {
    text-align: left;
    font-size: 45px;
  }

  @media all and (min-width: 768px) and (max-width: 1023px) {
    text-align: center;
    font-size: 50px;
  }

  @media all and (min-width: 480px) and (max-width: 768px) {
  }

  @media all and (max-width: 480px) {
    font-size: 28px;
    line-height: 35px;
    text-align: center;
  }
`

export const StyledTitleH2 = styled.h2`
  font-size: 45px;
  font-weight: 700;
  text-decoration: underline;
  text-decoration-color: #1f4398;

  @media all and (min-width: 1024px) and (max-width: 1280px) {
  }

  @media all and (min-width: 768px) and (max-width: 1023px) {
  }

  @media all and (min-width: 480px) and (max-width: 768px) {
  }

  @media all and (max-width: 480px) {
    font-size: 35px;
  }
`

export const StyledTitleH3 = styled.h3`
  font-weight: 700;
  font-size: 30px;

  @media all and (min-width: 1024px) and (max-width: 1280px) {
    font-size: 25px;
    text-align: center;
    line-height: 30px;
  }

  @media all and (min-width: 768px) and (max-width: 1023px) {
  }

  @media all and (min-width: 480px) and (max-width: 768px) {
  }

  @media all and (max-width: 480px) {
    font-size: 25px;
  }
`

export const StyledTitleH4 = styled.h4`
  font-size: 20px;
  font-weight: 200;
  line-height: 30px;

  @media all and (min-width: 1024px) and (max-width: 1280px) {
    text-align: left;
    font-size: 18px;
  }

  @media all and (min-width: 768px) and (max-width: 1023px) {
    text-align: center;
    font-size: 18px;
    line-height: 25px;
  }

  @media all and (min-width: 480px) and (max-width: 768px) {
    font-size: 16px;
  }

  @media all and (max-width: 480px) {
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    margin: 10px 0;
  }
`

export const StyledTitleH5 = styled.h5`
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  width: 200px;

  @media all and (min-width: 1024px) and (max-width: 1280px) {
  }

  @media all and (min-width: 768px) and (max-width: 1023px) {
  }

  @media all and (min-width: 480px) and (max-width: 768px) {
  }

  @media all and (max-width: 480px) {
    font-size: 18px;
  }
`
