import React from 'react'
import { StyledTitleH1, StyledTitleH4 } from '../../styles/styledComponets'
import {
  StyledAboutUsContent,
  StyledImgAbilityBoxContent,
  StyledImgAbilityContent
} from './styledComponents'
import Grammar from '../../assets/aboutUs/Grammar.png'
import Listening from '../../assets/aboutUs/Listening.png'
import Reading from '../../assets/aboutUs/Reading.png'
import Speaking from '../../assets/aboutUs/Speaking.png'
import Writing from '../../assets/aboutUs/Writing.png'
import { Image, Row } from 'antd'

const AboutUs: React.FC = () => {
  return (
    <StyledAboutUsContent>
      <StyledTitleH1>Nosotros</StyledTitleH1>
      <StyledTitleH4>
        Nuestro perfil educativo se enfoca en el desarrollo de las siguientes
        hábilidades en nuestra enseñanza
      </StyledTitleH4>
      <Row>
        <StyledImgAbilityBoxContent>
          <StyledImgAbilityContent alingItems="self-start">
            <Image src={Writing} preview={false} />
          </StyledImgAbilityContent>
          <StyledImgAbilityContent alingItems="flex-end">
            <Image src={Speaking} preview={false} />
          </StyledImgAbilityContent>
          <StyledImgAbilityContent alingItems="self-start">
            <Image src={Listening} preview={false} />
          </StyledImgAbilityContent>
          <StyledImgAbilityContent alingItems="flex-end">
            <Image src={Reading} preview={false} />
          </StyledImgAbilityContent>
          <StyledImgAbilityContent alingItems="self-start">
            <Image src={Grammar} preview={false} />
          </StyledImgAbilityContent>
        </StyledImgAbilityBoxContent>
      </Row>
    </StyledAboutUsContent>
  )
}

export default AboutUs
